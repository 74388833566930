import { FC, useContext } from "react";
import { TerrainLayerCreator } from "./terrain-layer-creator";

import { Action, Subjects } from "../../api/Permissions";
import { Col, Container, Row } from "react-bootstrap";
import { LangContext } from "src/lang/lang";
import { Can } from "src/casl/Can";
import { TerrainLayersTableView } from "./terrain-layers-table-view";

export const TerrainLayers: FC<unknown> = () => {
    const { ObjectNames } = useContext(LangContext);

    return (
        <Container className="section">
            <Row>
                <Col><h1>{ObjectNames.layers.en}</h1></Col>
                <Col md="auto">
                    <Can I={Action.Create} a={Subjects.TerrainLayer}>
                        <TerrainLayerCreator />
                    </Can></Col></Row>

            <TerrainLayersTableView />
        </Container>
    );
};
