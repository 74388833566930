import { FC, useContext, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useGetCourseSessionQuery } from "src/api/CourseSessionApi";
import { useCourseSessionControllerResetSessionMutation } from "src/api/generated.api";
import ErrorBar from "src/components/ErrorBar";
import { CourseSessionProgressOverview } from "./CourseSessionProgressOverview";
import { SlideProgressView } from "../slide-progress/SlideProgressView";
import { ConfirmButton } from "src/components/ConfirmButton";
import { LangContext } from "src/lang/lang";

export type CourseSessionProgressExplorerProps = {
    courseSessionId: number;
};

export const CourseSessionProgressExplorer: FC<
    CourseSessionProgressExplorerProps
> = ({ courseSessionId }) => {
    const {
        data,
        isFetching: isFetchingCourseSession,
        isError: isGetCourseSessionError,
        error: getCourseQueryError,
    } = useGetCourseSessionQuery(
        { courseSessionId },
        { refetchOnMountOrArgChange: true },
    );
    const { Sentences } = useContext(LangContext);

    const [currentSlideProgressId, setCurrentSlideProgressId] =
        useState<number>();

    const [resetSession] = useCourseSessionControllerResetSessionMutation();
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        isGetCourseSessionError
            ? setErrorMessage(JSON.stringify(getCourseQueryError))
            : setErrorMessage("");
    }, [isGetCourseSessionError, getCourseQueryError]);

    return (
        <>
            <ErrorBar errorMessage={errorMessage} />
            {isFetchingCourseSession && <div>Loading course session...</div>}
            {data && (
                <Container fluid className="mt-3 mb-3">
                    <Row>
                        <Col>
                            <h1>{data.name} progress</h1>
                        </Col>
                    </Row>
                </Container>
            )}
            <Container fluid className="mt-3 mb-3">
                <Row>
                    <Col md="auto" style={{ minWidth: "250px" }}>
                        <Card>
                            <Card.Header>Structure</Card.Header>
                            <CourseSessionProgressOverview
                                courseSessionId={courseSessionId}
                                onSlideProgressClick={(
                                    slideProgressId: number,
                                ) => setCurrentSlideProgressId(slideProgressId)}
                            />
                            <ConfirmButton
                                message={Sentences.resetSessionWarning.en}
                                variant="danger"
                                onClick={() =>
                                    resetSession({ courseSessionId })
                                }
                            >
                                Reset Session
                            </ConfirmButton>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header>Work details</Card.Header>
                            <Card.Body>
                                {currentSlideProgressId ? (
                                    <SlideProgressView
                                        slideProgressId={currentSlideProgressId}
                                    />
                                ) : (
                                    <div>
                                        Click on a slide in the structure panel
                                        to see its details
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
