import React, { ReactPropTypes, useContext } from "react";

import { Action, Subjects } from "@shared/auth/src";
import { AbilityContext } from "src/casl/Can";
import { Container } from "react-bootstrap";
import { Terrains } from "./Terrains";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";

export default function TerrainsPage() {
    const { Sentences } = useContext(LangContext);

    const ability = useContext(AbilityContext);

    return (<>

        {ability.can(Action.Read, Subjects.Terrains)
            ? <Terrains />
            : <ErrorBar errorMessage={Sentences.pageNotAllowed.en} />
        }

    </>
    )
}