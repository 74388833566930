import TableWithPagination from "src/components/tables/TableWithPagination";
import React, { FC, useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

import { TerrainModel, TerrainModelWithStatus, TerrainSummaryDto } from "src/api/generated.api";
import { LangContext } from "src/lang/lang";
import { useGetAllTerrainModelsQuery } from "src/api/TerrainModelApi";
import { Can } from "src/casl/Can";
import { Action, Subjects } from "src/api/Permissions";
import { subject } from "@casl/ability";
import { teamContext } from "src/features/teams/context/team-context-provider";
import moment from "moment";
import { Column } from "react-table";

interface TerrainModelTableSelectorProps {
    onTerrainModelSelected?: (terrainModel: TerrainModel) => void;
}

export const TerrainModelTableSelector: FC<TerrainModelTableSelectorProps> = ({
    onTerrainModelSelected,
}) => {
    const { ObjectNames, Sentences } = useContext(LangContext);

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const { currentTeam } = useContext(teamContext);

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const modelsQueryProps = {
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        teamId: currentTeam ? currentTeam.id : "",
        showPublicObjects: true
    }
    const {
        data: terrainsModel,
        isLoading,
        isFetching,
    } = useGetAllTerrainModelsQuery(modelsQueryProps);

    const columns: Column<TerrainModelWithStatus>[] = [
        {
            Header: 'Name',
            width: undefined,
            accessor: "name"
        },
        {
            Header: "Created at",
            width: 180,
            Cell: ({ row }: { row: { original: TerrainModel } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            Cell: ({ row }: { row: { original: TerrainModel } }) => (
                <Can
                    I={Action.Read}
                    this={subject(Subjects.TerrainModel, {
                        ...row.original,
                    })}
                >
                    <Button
                        onClick={() => {
                            if (onTerrainModelSelected)
                                onTerrainModelSelected(row.original);
                        }}
                    >
                        {Sentences.select.en}
                    </Button>
                </Can>
            ),
        },
    ];

    return (
        <TableWithPagination
            columns={columns}
            data={terrainsModel?.results ?? []}
            fetchData={(
                pageIndexToFetch: number,
                pageSizeToFetch: number,
                filterToFetch: string,
                sortToFetch: string
            ) => {
                setPageIndex(pageIndexToFetch);
                setPageSize(pageSizeToFetch);
                setFilter(filterToFetch);
                setSort(sortToFetch);
            }}
            loading={isFetching}
            itemCount={terrainsModel?.total || 0}
            enableFiltering={true}
            defaultSort="createdAt:desc"
            sortableProps={[
                { name: 'Name', accessor: 'name' },
                { name: 'Created at', accessor: 'createdAt' }
            ]}
        />
    );
};
