import { Modal, Button } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCreateActivityMutation } from "src/api/ActivityApi";
import { CreateActivityDto } from "src/api/generated.api";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { LangContext } from "src/lang/lang";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import { teamContext } from "../teams/context/team-context-provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export function ActivityModal() {
    const { ObjectNames, Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);
    const [show, setShow] = useState(false);
    const [createActivity, { isError: createIsError, error: createError }] = useCreateActivityMutation();
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (createIsError) {
            setErrorMessage(JSON.stringify(createError));
        }
    }, [createIsError, createError]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onCreate = async (values: CreateActivityDto, formikBag: FormikHelpers<CreateActivityDto>) => {
        try {
            const activity = await createActivity({ createActivityDto: values }).unwrap();
            navigate(`/activities/${activity.id}`);
            handleClose();
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <FontAwesomeIcon
                    className="mr-2"
                    icon={faPlus}
                />{" "}Add slide deck
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create slide deck</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            name: "",
                            teamId: currentTeam ? currentTeam.id : "",
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string().required(),
                            teamId: Yup.string().uuid().required(),
                        })}
                        onSubmit={onCreate}
                        enableReinitialize
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder={"New " + ObjectNames.activity.en + " name"}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    isInvalid={!!formik.errors.name && formik.touched.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.name}
                                </Form.Control.Feedback>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShow(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" variant="primary" >
                                        Create
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                    {errorMessage && <div className="text-danger">{errorMessage}</div>}
                </Modal.Body>
            </Modal>
        </>
    );
}
