import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RoutesDef from "src/routes/RoutesDef";


export const Tutorials: FC<any> = () => {

    const shortcuts = [
        { label: "Learn how to add your first model and terrain in VRExplorer", path: '/tutorials/VRExplorer_web_QuickStart.pdf' },
        { label: "Guide to the first launch of the VRExplorer 2D Client", path: '/tutorials/VRExplorer_client_QuickStart.pdf' },

    ];

    return (
        <>
            <div className="bg text-center row">
                <Col className="my-auto">
                    <h1 className="display-5">Quick start guides</h1>
                    <Row className="mx-auto mt-4 " style={{ width: "500px" }}>
                        {shortcuts.map((shortcut, index) => (
                            <a
                                className="mb-3 mx-auto text-white"
                                style={{ fontSize: '0.9em' }}
                                href={shortcut.path}
                            ><FontAwesomeIcon
                                    className="mr-2"
                                    icon={faBook}
                                />{" "}
                                {shortcut.label}
                            </a>
                        ))}
                    </Row>
                </Col>
            </div>
        </>
    );
};
