import { createContext } from "react";

export const ObjectNames = {
    appName: {
        en: "...",
    },
    activity: {
        en: "Slide Deck",
    },
    activities: {
        en: "Slide Decks",
    },
    binaryArtifacts: {
        en: "Binary artifacts",
    },
    binaryArtifactsRelease: {
        en: "Releases",
    },
    courseSession: {
        en: "Session",
    },
    courseSessions: {
        en: "Sessions",
    },
    courseSessionRecords: {
        en: "Session Records",
    },
    slide: {
        en: "Slide",
    },
    slides: {
        en: "Slides",
    },
    terrain: {
        en: "Scene",
    },
    terrains: {
        en: "Scenes",
    },
    terrainModel: {
        en: "Model",
    },
    terrainModels: {
        en: "Models",
    },
    user: {
        en: "User",
    },
    users: {
        en: "Users",
    },
    layer: {
        en: "Layer",
    },
    managedLayer: {
        en: "Managed TMS Layer",
    },
    layers: {
        en: "Layers",
    },
    models3d: {
        en: "3D Models",
    },
    model3d: {
        en: "3D Model",
    },
    student: {
        en: "Student",
    },
    students: {
        en: "Students",
    },
    medias: {
        en: "Medias",
    },
    timestamp: {
        en: "Timestamp",
    },
    teamRoles: {
        en: "Team Roles",
    },
};

export const generateSentences = (objectNames: any) => {
    return {
        about: {
            en: `About ${objectNames.appName.en}`,
        },
        or: {
            en: "or",
        },
        activityName: {
            en: `${objectNames.activity.en} name`,
        },
        activityDescription: {
            en: `${objectNames.activity.en} description`,
        },
        activityAdd: {
            en: `Add existing ${objectNames.activity.en}`,
        },
        activityEdit: {
            en: `Edit ${objectNames.activity.en}`,
        },
        activityEditSubtitle: {
            en: `Create your ${objectNames.slides.en} and quiz for ${objectNames.courseSession.en}`,
        },
        activityDelete: {
            en: `Remove ${objectNames.activity.en}`,
        },
        activitySelect: {
            en: `Select ${objectNames.activity.en}`,
        },
        activityCreate: {
            en: `Create ${objectNames.activity.en}`,
        },
        terrainEdit: {
            en: `Edit ${objectNames.terrain.en}`,
        },
        terrainEditSubtitle: {
            en: `Edit your ${objectNames.terrain.en}`,
        },
        terrainSelect: {
            en: `select ${objectNames.terrain.en}`,
        },
        terrainNoSelected: {
            en: `No ${objectNames.terrain.en} selected`,
        },
        terrainChoose: {
            en: `Choose ${objectNames.terrain.en}`,
        },
        terrainRemove: {
            en: `Remove ${objectNames.terrain.en}`,
        },
        terrainDelete: {
            en: `Delete ${objectNames.terrain.en}`,
        },
        terrainCreate: {
            en: `Create ${objectNames.terrain.en}`,
        },
        terrainName: {
            en: `${objectNames.terrain.en} name`,
        },
        terrainBounds: {
            en: `${objectNames.terrain.en} bounds`,
        },
        courseSessionEdit: {
            en: `Edit ${objectNames.courseSession.en}`,
        },
        courseSessionEditSubtitle: {
            en: `Create and edit your ${objectNames.courseSession.en}`,
        },
        courseSessionRecordsSubtitle: {
            en: `Explore your work performed during ${objectNames.courseSessions.en}`,
        },
        courseSessionsMine: {
            en: `My ${objectNames.courseSessions.en}`,
        },
        courseSessionsEnrolled: {
            en: `Enrolled in ${objectNames.courseSessions.en}`,
        },
        courseSessionDeleteAreYouSure: {
            en: `Are you sure ? This will also delete all data produced by ${objectNames.students.en} during the ${objectNames.courseSession.en}.`,
        },
        courseSessionName: {
            en: `${objectNames.courseSession.en} name`,
        },
        courseSessionStartupModeLabel: {
            en: `Mode at ${objectNames.courseSession.en} startup`,
        },
        courseSessionErrorLoading: {
            en: `Error loading ${objectNames.courseSession.en}`,
        },
        courseSessionHostLabel: {
            en: "Session Host",
        },
        courseSessionChangeHostLabel: {
            en: "Change Host",
        },
        courseSessionHostSelect: {
            en: "Select new Host",
        },
        slideEditor: {
            en: `${objectNames.slide.en} editor`,
        },
        slideTitle: {
            en: `${objectNames.slide.en} title`,
        },
        slideEdit: {
            en: `Edit ${objectNames.slide.en}`,
        },
        slideAdd: {
            en: `Add ${objectNames.slide.en}`,
        },
        slideCreate: {
            en: `Create ${objectNames.slide.en}`,
        },
        slideDelete: {
            en: `Delete ${objectNames.slide.en}`,
        },
        slideErrorLoading: {
            en: `Error loading ${objectNames.slide.en}`,
        },
        slideNoSelected: {
            en: `No ${objectNames.slide.en} selected`,
        },
        userCreate: {
            en: `Create ${objectNames.user.en}`,
        },
        createANewOne: {
            en: "Create a new one",
        },
        save: {
            en: "Save",
        },
        saving: {
            en: "Saving...",
        },
        createCopy: {
            en: "Create a copy",
        },
        select: {
            en: "Select",
        },
        cancel: {
            en: "Cancel",
        },
        close: {
            en: "Close",
        },
        notAllowedToListActivities: {
            en: `You're not allowed to list the ${objectNames.activities.en}`,
        },
        notAllowedToListSlides: {
            en: `You're not allowed to list the ${objectNames.slides.en}`,
        },
        notAllowedToListSessions: {
            en: `You're not allowed to list the ${objectNames.courseSessions.en}`,
        },
        pageNotAllowed: {
            en: "Sorry you're not allowed to access this page. Check your permissions with your administrator.",
        },
        noContentToPreview: {
            en: "No content to preview",
        },
        title: {
            en: "Title",
        },
        slidePosition: {
            en: `${objectNames.slide.en} position`,
        },
        slideContent: {
            en: `${objectNames.slide.en} content`,
        },
        edit: {
            en: "Edit",
        },
        create: {
            en: "Create",
        },
        delete: {
            en: "Delete",
        },
        preview: {
            en: "Preview",
        },
        generateSampleContent: {
            en: "Generate Sample Content",
        },
        generateSampleContentDesc: {
            en: `${objectNames.slide.en} content in markdown format. Click generate content to get a sample.`,
        },
        content: {
            en: "Content",
        },
        interactiveContent: {
            en: "Quiz Content",
        },
        atSlideStartup: {
            en: `At ${objectNames.slide.en} startup`,
        },
        slideUseDefaultTerrainPosition: {
            en: `Use default ${objectNames.terrain.en} position`,
        },
        slideUseCustomTerrainPosition: {
            en: `Use custom ${objectNames.terrain.en} position`,
        },
        slideUseLatestOrDefault: {
            en: `Do not move user if latest ${objectNames.slide.en} was on the same ${objectNames.terrain.en}. otherwise use default ${objectNames.terrain.en} position`,
        },
        slideUseLatestOrCustom: {
            en: `Do not move user if latest ${objectNames.slide.en} was on the same ${objectNames.terrain.en}. otherwise use custom ${objectNames.terrain.en} position`,
        },
        slideCustomPositionLabel: {
            en: "Custom Position (click on map to define)",
        },
        slideViewHeadingLabel: {
            en: "View heading",
        },
        slideMapScaleLabel: {
            en: "Map Scale (1 meter in VR = x meters on terrain)",
        },
        slidePositionMapLegend: {
            en: "Red / Green / Blue circles are 300 / 10 / 2 meters range in VR. Yellow triangle is default prof head orientation",
        },
        myProfile: {
            en: "My profile",
        },
        logout: {
            en: "Log out",
        },
        login: {
            en: "Log in",
        },
        loggedInAs: {
            en: "Logged in as ",
        },
        signIn: {
            en: "Sign in",
        },
        signInSocialInstructions: {
            en: `Welcome ! Sign in with your social account or local ${objectNames.appName.en} account.`,
        },
        signInInstructions: {
            en: `Welcome ! Sign in with your local ${objectNames.appName.en} account.`,
        },
        welcome: {
            en: `Welcome to ${objectNames.appName.en}`,
        },
        loginWithCas: {
            en: "Login with CAS",
        },
        layerName: {
            en: "Name",
        },
        layerDetailsJsonUrl: {
            en: `${objectNames.layer.en} details.json url`,
        },
        connectionHeaderAndValues: {
            en: "Connection headers and values",
        },
        textureAndElevation: {
            en: "Textures & Elevation",
        },
        layerAddExternal: {
            en: `Add New External ${objectNames.layer.en}`,
        },
        terrainLayerIs: {
            en: `${objectNames.layer.en} is`,
        },
        layerIsExternal: {
            en: `stored outside of vrexplorer`,
        },
        layerIsInternal: {
            en: `stored on vrexplorer server`,
        },
        layerCreate: {
            en: `Create ${objectNames.layer.en}`,
        },
        layerDeleteSentence: {
            en: `This will delete this layer and detach it from all terrains. Are you Sure ?`,
        },
        managedLayerEditSubtitle: {
            en: `Create a new internaly managed ${objectNames.layer.en}`,
        },
        binaryArtifactsEditSubtitle: {
            en: `Manage build artifacts of desktop client applications`,
        },
        terrainNoModelAttached: {
            en: `No ${objectNames.terrainModels.en} attached to this ${objectNames.terrain.en}`,
        },
        terrainUseButtonToAttachModel: {
            en: `Use the button below to attach some ${objectNames.terrainModels.en}.`,
        },
        terrainModelAdd: {
            en: `Add ${objectNames.terrainModel.en}`,
        },
        terrainModelDelete: {
            en: `Delete ${objectNames.terrainModel.en}`,
        },
        terrainModelSelect: {
            en: `Select ${objectNames.terrainModel.en}`,
        },
        terrainDeleteAreYouSure: {
            en: `Are you sure that you want to delete this ${objectNames.terrain.en} ?`,
        },
        terrainModelCreate: {
            en: `Create ${objectNames.terrainModel.en}`,
        },
        terrainModelName: {
            en: `${objectNames.terrainModel.en} name`,
        },
        terrainModelIs: {
            en: `${objectNames.terrainModel.en} type is`,
        },
        terrainModelPath: {
            en: `${objectNames.terrainModel.en} path`,
        },
        defaultPosition: {
            en: "Default position",
        },
        defaultRotation: {
            en: "Default rotation",
        },
        defaultScale: {
            en: "Default scale",
        },
        defaultTransformMatrix: {
            en: "Default transform matrix",
        },
        modelEmbeded: {
            en: "unity asset bundle embeded in VR app",
        },
        modelRemote: {
            en: "unity asset bundle loaded from a location",
        },
        tilesRemote: {
            en: "3d tiles loaded from a location",
        },
        pointCloudLocal: {
            en: "Point cloud stored on vrexplorer server",
        },
        oldTilesLocal: {
            en: "old 3d tiles stored on vrexplorer server (deprecated)",
        },
        oldTilesRemote: {
            en: "old 3d tiles loaded from a location (deprecated)",
        },
        glbFileRemote: {
            en: "glb file loaded from a location",
        },
        glbFileLocal: {
            en: "glb file stored on vrexplorer server",
        },
        tilesLocal: {
            en: "3d tiles stored on vrexplorer server",
        },
        modelPoseResetToDefault: {
            en: `Reset to ${objectNames.terrainModel.en} values`,
        },
        itemPerPages: {
            en: `Items per pages`,
        },
        terrainNotFound: {
            en: `Sorry, unknown ${objectNames.terrain.en}`,
        },
        layerNotFound: {
            en: `Sorry, unknown ${objectNames.layer.en}`,
        },
        managedLayerNotFound: {
            en: `Sorry, unknown ${objectNames.managedLayer.en}`,
        },
        userDetails: {
            en: `${objectNames.user.en} details`,
        },
        userAddLocalPassword: {
            en: "Add local password",
        },
        userAddCasAccount: {
            en: "Add Cas Account",
        },
        userAddGoogleAccount: {
            en: "Add Google Account",
        },
        userSocialAccountUsername: {
            en: "Social account username",
        },
        userPassword: {
            en: "Password",
        },
        userEditSubtitle: {
            en: "Manage your students and professors",
        },
        studentAdd: {
            en: `Add ${objectNames.student.en}`,
        },
        studentSelect: {
            en: `Select ${objectNames.student.en}`,
        },
        resetSessionWarning: {
            en: `Are you sure you want to reset this ${objectNames.courseSession.en} ? this will delete all data produced by ${objectNames.students.en} during the ${objectNames.courseSession.en}.`,
        },
        unlinkActivityWarning: {
            en: `Are you sure you want to delete this ${objectNames.activity.en} ? this will also delete all data produced by ${objectNames.students.en} during the ${objectNames.courseSession.en}.`,
        },
        unlinkStudentWarning: {
            en: `Are you sure you want to remove this ${objectNames.student.en} from the ${objectNames.courseSession.en} ? this will also delete all data this ${objectNames.student.en} has produced during the ${objectNames.courseSession.en}.`,
        },
        jobStatus: {
            en: "Status",
        },
        logLine: {
            en: "Log Line",
        },
        binaryArtifactsReleaseEditSubtitle: {
            en: "Edit which artifact are releases for download",
        },
        teamRolesSubtitles: {
            en: "User Roles and Permissions",
        },
        editTeamRoleTitle: {
            en: "Team Role",
        },
        editTeamRoleSubtitles: {
            en: "Edit User Role name and Permissions",
        },
        myTeamTitle: {
            en: "My team",
        },
        myTeamSubtitle: {
            en: "View your team and its users",
        },
        teamEditTitle: {
            en: "Teams",
        },
        teamEditSubtitle: {
            en: "Manage your teams",
        },
        teamEdit2Subtitle: {
            en: "Edit your team, add and remove members, update roles",
        },
    };
};

export const Sentences = generateSentences(ObjectNames);
export const LangContext = createContext({ ObjectNames, Sentences });
