import { Formik, FormikHelpers } from "formik";
import { FC, useEffect, useState, useContext } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CreateLayerDto } from "src/api/generated.api";
import { useGetTerrainModelsCapabilitiesQuery } from "src/api/TerrainModelApi";
import { BootstrapDropdownKeys } from "src/components/BootstrapFormComponents";
import ErrorBar from "src/components/ErrorBar";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { LangContext } from "src/lang/lang";
import { teamContext } from "../teams/context/team-context-provider";
import {
    LayerManagementModeTypes,
    useLayerManagementMode,
} from "./terrain-layer-types";
import { useCreateLayerMutation } from "src/api/LayerApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export type CreateTerrainModelProps = {};

export const TerrainLayerCreator: FC<CreateTerrainModelProps> = () => {
    const { Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);
    const [createLayer, { isError: createIsError, error: createError }] =
        useCreateLayerMutation();

    const {
        data,
        isError: isErrorFetchingCapabilities,
        error: capError,
    } = useGetTerrainModelsCapabilitiesQuery();

    const capabilities: LayerManagementModeTypes[] = ["EXTERNAL", "INTERNAL"];
    const layerManagementModes = useLayerManagementMode();

    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const isErr = isErrorFetchingCapabilities || createIsError;
        const err = [capError, createError].filter((v) => v !== undefined);
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [capError, createError, createIsError, isErrorFetchingCapabilities]);

    const navigate = useNavigate();

    const onCreate = async (
        values: CreateLayerDto,
        formikBag: FormikHelpers<CreateLayerDto>
    ) => {
        try {
            const terrainLayer = await createLayer({
                createLayerDto: values,
            }).unwrap();
            navigate(`/terrain-layers/${terrainLayer.id}`);
            setShowModal(false);
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
    };

    return (
        <div>
            <ErrorBar errorMessage={errorMessage} />
            <Button variant="primary" onClick={() => setShowModal(true)}>
                <FontAwesomeIcon
                    className="mr-2"
                    icon={faPlus}
                />{" "}Add Layer
            </Button>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Layer</Modal.Title>
                </Modal.Header>
                {capabilities && capabilities.length > 0 ? (
                    <Formik<CreateLayerDto>
                        initialValues={{
                            name: "",
                            managementMode: layerManagementModes.filter(({ id }) =>
                                (capabilities || []).includes(id),
                            )[0].id,
                            teamId: currentTeam ? currentTeam.id : "",
                        }}
                        onSubmit={onCreate}
                        enableReinitialize
                    >
                        {({ handleSubmit, handleChange, values, errors, touched }) => (
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Group controlId="layerName">
                                        <Form.Label>{Sentences.layerName.en}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={touched.name && !!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <BootstrapDropdownKeys
                                        items={layerManagementModes.filter(({ id }) =>
                                            (capabilities || []).includes(id),
                                        )}
                                        label={Sentences.terrainLayerIs.en}
                                        name="managementMode"
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" variant="primary">
                                        Create
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <Modal.Body>
                        <div>No Layer storage capabilities available on server.</div>
                    </Modal.Body>
                )}
            </Modal>
        </div>
    );
};
