import TableWithPagination from "../../components/tables/TableWithPagination";
import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Action, Subjects } from "src/api/Permissions";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Column } from "react-table";
import {
    useCreateTerrainMutation,
    useDeleteTerrainMutation,
    useFindAllTerrainsQuery,
} from "src/api/TerrainApi";
import { CreateTerrainDto, Terrain, TerrainSummaryDto } from "../../api/generated.api";
import { Can } from "../../casl/Can";
import { Formik, FormikHelpers } from "formik";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import * as Yup from "yup";
import { teamContext } from "../teams/context/team-context-provider";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { subject } from "@casl/ability";
import { ViewOrEditLinkButton } from "src/components/view-or-edit-link-button";
import moment from "moment";
import { TerrainLayersTableProps } from "../layer/terrain-layers-table-selector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";


export function Terrains() {
    const { ObjectNames, Sentences } = useContext(LangContext);

    const { currentTeam } = useContext(teamContext);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [showPublicObjects, setShowPublicObjects] = React.useState(false);


    const navigate = useNavigate();

    const terrainsQueryProps = {
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        teamId: currentTeam ? currentTeam.id : "",
        showPublicObjects
    }

    const {
        data: terrains,
        isLoading,
        isError,
        error,
    } = useFindAllTerrainsQuery(terrainsQueryProps);
    const [
        deleteTerrain,
        { isError: deleteTerrainIsError, error: deleteTerrainError },
    ] = useDeleteTerrainMutation();
    const [
        createTerrain,
        { isError: createTerrainIsError, error: createTerrainError },
    ] = useCreateTerrainMutation();

    const onCreate = async (
        values: CreateTerrainDto,
        formikBag: FormikHelpers<CreateTerrainDto>,
    ) => {
        try {
            const terrain = await createTerrain({
                createTerrainDto: values,
            }).unwrap();
            navigate(`/terrains/${terrain.id}`);
        } catch (e) {
            try {
                const errors = convertApiErrorsToFormikErrors(e);
                formikBag.setErrors(errors);
            } catch (fe) {
                setErrorMessage(JSON.stringify(e));
            }
        }
    };

    useEffect(() => {
        const isErr = isError || deleteTerrainIsError;
        const err = [error, deleteTerrainError].filter((v) => v !== undefined);
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [isError, error, deleteTerrainIsError, deleteTerrainError]);

    const columns: Column<TerrainSummaryDto>[] = [
        {
            Header: 'Name',
            width: undefined,
            accessor: "name"
        },
        {
            Header: "Visibility",
            width: 150,
            accessor: "visibility"
        },
        {
            Header: "Created at",
            width: 180,
            Cell: ({ row }: { row: { original: TerrainSummaryDto } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 130,
            Cell: ({ row }: { row: { original: TerrainSummaryDto } }) => (
                <div>
                    <ViewOrEditLinkButton
                        editLink={"/terrains/" + row.original.id}
                        readLink={"/terrains/" + row.original.id}
                        subjectType={Subjects.Terrains}
                        obj={row.original}
                    />
                    <Can
                        I={Action.Delete}
                        this={subject(Subjects.TerrainModel, {
                            ...row.original,
                        })}
                    >
                        <DeleteButtonWithConfirm
                            variant="secondary"
                            className="ml-1"
                            onClick={() =>
                                deleteTerrain({ terrainId: row.original.id })
                            }
                        />
                    </Can>
                </div>
            ),
        },
    ];

    return (
        <Container className="section">
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Scene</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        name: "",
                        teamId: currentTeam?.id || ""
                    }}
                    onSubmit={onCreate}
                    validationSchema={Yup.object({ name: Yup.string().required() })}
                >
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Form.Group controlId="terrainName">
                                    <Form.Label>{Sentences.terrainName.en}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={touched.name && !!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Cancel
                                </Button>
                                <Button type="submit" variant="primary">
                                    Create
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <div className="mb-3">
                <Row>
                    <Col xs={'auto'}>
                        <h1>{ObjectNames.terrains.en}</h1>
                    </Col>
                    <Col className="text-right">
                        <Can I={Action.Create} a={Subjects.Terrains}>
                            <Button variant="primary" onClick={() => setShowModal(true)}>
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faPlus}
                                />{" "}Add Scene
                            </Button>
                        </Can>
                    </Col>
                </Row>
            </div>
            <div>
                <ErrorBar errorMessage={errorMessage} />

            </div>

            <TableWithPagination
                fetchData={(
                    pageIndexToFetch: number,
                    pageSizeToFetch: number,
                    filterToFetch: string,
                    sortToFetch: string,
                    showPublicObjectsToFetch: boolean
                ) => {
                    setPageIndex(pageIndexToFetch);
                    setPageSize(pageSizeToFetch);
                    setFilter(filterToFetch);
                    setSort(sortToFetch);
                    setShowPublicObjects(showPublicObjectsToFetch);
                }}
                showPublicObjectsToggle={true}
                loading={isLoading}
                columns={columns}
                data={terrains?.results ?? []}
                itemCount={terrains?.total ?? 0}
                enableFiltering={true}
                defaultSort="createdAt:desc"
                sortableProps={[
                    { name: 'Name', accessor: 'name' },
                    { name: 'Visibility', accessor: 'visibility' },
                    { name: 'Created at', accessor: 'createdAt' }
                ]}
            />
        </Container>
    );
}
