import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Action, Subjects } from "src/api/Permissions";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    useCreateCourseSessionMutation,
    useDeleteCourseSessionMutation,
    useGetAllCourseSessionsQuery,
} from "src/api/CourseSessionApi";
import { CourseSession, CreateCourseSessionDto, useCourseSessionControllerFindAllPaginatedQuery } from "src/api/generated.api";
import ConfirmDelete from "src/components/ConfirmDelete";
import TableSimple from "src/components/tables/TableSimple";
import { Badge, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Can } from "src/casl/Can";
import { Formik, FormikHelpers } from "formik";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import * as Yup from "yup";
import { EnrolledSessions } from "../session-records/EnrolledSessions";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import { teamContext } from "../teams/context/team-context-provider";
import { OwnedSessions } from "../session-records/OwnedSessions";
import { faEye, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { subject } from "@casl/ability";
import { ViewOrEditLinkButton } from "src/components/view-or-edit-link-button";
import moment from "moment";
import TableWithPagination from "src/components/tables/TableWithPagination";

export default function Sessions() {
    const { ObjectNames, Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const [errorMessage, setErrorMessage] = React.useState("");

    const queryProps = {
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        teamId: currentTeam ? currentTeam.id : ""
    }

    const [createObject, { isError: createIsError, error: createError }] =
        useCreateCourseSessionMutation();
    const [deleteObject, { isError: deleteIsError, error: deleteError }] =
        useDeleteCourseSessionMutation();
    const {
        data: myCourseSessions,
        isFetching,
        isError: getIsError,
        error: getError,
    } = useCourseSessionControllerFindAllPaginatedQuery(queryProps);

    const objectName = ObjectNames.courseSessions.en;
    const title = ObjectNames.courseSessions.en;
    const deleteMessage = Sentences.courseSessionDeleteAreYouSure.en;

    const objectEditRoute = "/sessions";
    type objectType = CourseSession;

    const navigate = useNavigate();

    const onCreate = async (
        values: CreateCourseSessionDto,
        formikBag: FormikHelpers<CreateCourseSessionDto>,
    ) => {
        try {
            const obj = await createObject({
                createCourseSessionDto: values,
            }).unwrap();
            navigate(objectEditRoute + "/" + obj.id);
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
    };
    const myColumns = [
        {
            Header: ObjectNames.courseSession.en,
            width: undefined,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>{row.original.name}</div>
            ),
        },
        {
            Header: Sentences.courseSessionHostLabel.en,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>
                    {row.original.owners.map((user) => (
                        <Badge>{user.username}</Badge>
                    ))}
                </div>
            ),
        },
        {
            Header: "Created at",
            width: 180,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 130,
            Cell: ({ row }: { row: { original: objectType } }) => (
                <div>
                    <ViewOrEditLinkButton
                        editLink={objectEditRoute + "/" + row.original.id}
                        readLink={objectEditRoute + "/" + row.original.id}
                        subjectType={Subjects.CourseSessions}
                        obj={row.original}
                    />
                    <Can
                        I={Action.Delete}
                        this={subject(Subjects.CourseSessions, {
                            ...row.original,
                        })}
                    >
                        <DeleteButtonWithConfirm
                            variant="secondary"
                            className="ml-1"
                            onClick={() =>
                                deleteObject({
                                    courseSessionId: row.original.id,
                                })
                            }
                            message={deleteMessage}
                        />
                    </Can>
                </div>
            ),
        },
    ];
    useEffect(() => {
        const isErr = createIsError;
        const err = [createError].filter((v) => v !== undefined);
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [createIsError, createError]);

    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <div>
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            name: "",
                            teamId: currentTeam ? currentTeam.id : "",
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string().required(),
                            teamId: Yup.string().uuid().required(),
                        })}
                        onSubmit={async (values, formikBag) => {
                            await onCreate(values, formikBag);
                            handleClose(); // Ferme le modal après soumission
                        }}
                        enableReinitialize
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit} method="post">
                                <FkStackedTextInputWithButton
                                    label="Name"
                                    name="name"
                                    placeholder="Enter session name"
                                    buttonText="Create"
                                />
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <div className="section mb-3">
                <Row>
                    <Col>
                        <h1>{title}</h1><div className="subtitle">
                            {Sentences.courseSessionEditSubtitle.en}
                        </div>
                    </Col>
                    <Col md="auto"><Can
                        I={Action.Create}
                        this={subject(Subjects.CourseSessions, {
                            teamId: currentTeam?.id,
                        })}
                    >
                        <Button variant="primary" className="mb-3" onClick={handleShow}>
                            <FontAwesomeIcon
                                className="mr-2"
                                icon={faPlus}
                            />{" "}Add session
                        </Button>
                    </Can>
                    </Col>
                </Row>

            </div>
            <div>
                <ErrorBar errorMessage={errorMessage} />

            </div>
            <div className="section">

                <TableWithPagination
                    fetchData={(
                        pageIndexToFetch: number,
                        pageSizeToFetch: number,
                        filterToFetch: string,
                        sortToFetch: string
                    ) => {
                        setPageIndex(pageIndexToFetch);
                        setPageSize(pageSizeToFetch);
                        setFilter(filterToFetch);
                        setSort(sortToFetch);
                    }}
                    loading={isFetching}
                    columns={myColumns}
                    data={myCourseSessions?.results ?? []}
                    itemCount={myCourseSessions?.total ?? 0}
                    enableFiltering={true}
                    sortableProps={[
                        { name: 'Session', accessor: 'name' },
                        { name: 'Created at', accessor: 'createdAt' }
                    ]}
                />
            </div>
        </div>
    );
}
