import { FC, useContext, useState } from "react";
import {
    Team,
    TeamSummaryForUserDto,

} from "src/api/team.api";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { faEnvelope, faMailBulk, faPlusCircle, faUserCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TeamRole, useFindMineUserQuery, useMembershipControllerGetMembersQuery, useTeamStorageControllerGetStorageStatsQuery } from "src/api/generated.api";
import { TeamInvite } from "./team-invite";
import { AppInfoContext } from "../AppVersionWatcher/BackendVersionProvider";
import RoutesDef from "src/routes/RoutesDef";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../auth/authSlice";
import { Can } from "src/casl/Can";
import { Action, Subjects } from "src/api/Permissions";
import { useNavigate } from "react-router-dom";

export type TeamOverviewEditorProps = {
    team: TeamSummaryForUserDto;
};

export const TeamOverview: FC<TeamOverviewEditorProps> = ({ team }) => {

    const appVersion = useContext(AppInfoContext);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const { teamId, adminCountLimit, guestCountLimitByAdmin, storageCapacityLimitByAdmin } = team;
    const { data: members, isLoading, refetch } = useMembershipControllerGetMembersQuery(
        { teamId },
    );

    const user = useSelector(selectLoggedInUser);

    const { data: storage } = useTeamStorageControllerGetStorageStatsQuery({ teamId },
    );

    const handleCloseInvite = () => { setShowInviteModal(false); refetch(); }
    const handleShowInvite = () => setShowInviteModal(true);

    let adminCount: number = 0;
    let guestCount: number = 0;
    let enableAdminInvitation: boolean = false;
    let enableGuestInvitation: boolean = false;
    let emailEnabled = appVersion?.emailEnabled === true;

    if (members) {
        adminCount = members.filter((m) => m.role?.permissions.length).length;
        guestCount = members.length - adminCount;
        enableAdminInvitation = adminCount < adminCountLimit || (appVersion?.noQuotas || false);
        enableGuestInvitation = guestCount < guestCountLimitByAdmin || (appVersion?.noQuotas || false);
    }

    const navigate = useNavigate();

    return (<>
        {team && appVersion && (
            <>
                <div className="my-4 ">
                    <h5>Overview</h5>
                    <Row className="teamOverview">
                        <Col>
                            <div className="title">Admin Members</div>
                            <div className="count">{adminCount}{!appVersion?.noQuotas && <span className="limit">/{adminCountLimit}</span>}</div>
                        </Col>
                        <Col>
                            <div className="title">Guest Members</div>
                            <div className="count">{guestCount}{!appVersion?.noQuotas && <span className="limit">/{guestCountLimitByAdmin * adminCountLimit}</span>}</div>
                        </Col>
                        <Col>
                            <div className="title">Storage</div>
                            {storage && <div className="count">{(storage.total / 1000).toFixed(1)} Go{!appVersion?.noQuotas && <span className="limit">/{storageCapacityLimitByAdmin * adminCountLimit}</span>}</div>}
                        </Col>
                        <Col>
                            <Row className="mb-3 justify-content-end">
                                {emailEnabled &&
                                    <Button
                                        className="w-100"

                                        onClick={handleShowInvite}
                                        disabled={!appVersion?.noQuotas && adminCount >= adminCountLimit && guestCount >= guestCountLimitByAdmin * adminCountLimit}>
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faUserPlus}
                                        />{" "}Invite new members</Button>
                                }
                            </Row>
                            <Can I={Action.Create} a={Subjects.TeamMembership}>
                                <Row className="mb-3 justify-content-end">
                                    <Button
                                        className="w-100"

                                        onClick={() => navigate(RoutesDef.TEAM_USERS_CREATE.split(':')[0] + teamId)}
                                        disabled={!appVersion?.noQuotas && adminCount >= adminCountLimit && guestCount >= guestCountLimitByAdmin * adminCountLimit}>
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faUserPlus}
                                        />{" "}Create new members</Button>
                                </Row>
                            </Can>
                            {user?.role != 'ADMIN' &&
                                <Row className="justify-content-end">
                                    <Button className="w-100" href="mailto:vrexplorer-support@vr2planets.com"> <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faEnvelope}
                                    />{" "}Request for new seats</Button>
                                </Row>}

                        </Col>
                    </Row>
                </div>
                <TeamInvite team={team} show={showInviteModal} enableAdminInvitation={enableAdminInvitation} handleClose={handleCloseInvite} />
            </>


        )
        }
    </>
    );
};
