import { Action, Subjects } from "src/api/Permissions";
import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
    useCreateActivityMutation,
    useDeleteActivityMutation,
    useGetAllActivitiesQuery,
} from "src/api/ActivityApi";
import { Activity, CreateActivityDto } from "src/api/generated.api";
import { Can } from "src/casl/Can";
import TableWithPagination from "src/components/tables/TableWithPagination";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import * as Yup from "yup";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { FkStackedTextInputWithButton } from "src/components/FkStackedTextInputWithButton";
import { teamContext } from "../teams/context/team-context-provider";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { subject } from "@casl/ability";
import { ViewOrEditLinkButton } from "src/components/view-or-edit-link-button";
import { Header } from "src/stories/Header";
import { Column } from "react-table";
import moment from "moment";
import { ActivityModal } from "./ActivityModal";

export function Activities() {
    const { ObjectNames, Sentences } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);
    const [deleteActivity, { isError: deleteIsError, error: deleteError }] =
        useDeleteActivityMutation();
    const [createActivity, { isError: createIsError, error: createError }] =
        useCreateActivityMutation();

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");
    const [showPublicObjects, setShowPublicObjects] = React.useState(false);

    const allActivitiesQueryProps = {
        limit: pageSize,
        offset: pageIndex * pageSize,
        filter,
        sort,
        teamId: currentTeam ? currentTeam.id : "",
        showPublicObjects: showPublicObjects
    };

    const {
        data,
        isFetching,
        isError: getIsError,
        error: getError,
    } = useGetAllActivitiesQuery(allActivitiesQueryProps, {
        refetchOnMountOrArgChange: true,
    });

    const navigate = useNavigate();
    const onCreate = async (
        values: CreateActivityDto,
        formikBag: FormikHelpers<CreateActivityDto>,
    ) => {
        try {
            const activity = await createActivity({
                createActivityDto: values,
            }).unwrap();
            navigate(`/activities/${activity.id}`);
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
    };

    useEffect(() => {
        const isErr = getIsError || deleteIsError || createIsError;
        const err = [getError, deleteError, createError].filter(
            (v) => v !== undefined,
        );
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [
        getIsError,
        deleteIsError,
        createIsError,
        getError,
        deleteError,
        createError,
    ]);

    const columns: Column<Activity>[] = [
        {
            Header: "Name",
            width: undefined,
            Cell: ({ row }: { row: { original: Activity } }) => (
                <div>{row.original.name}</div>
            ),
        },
        { Header: "Visibility", accessor: "visibility" },
        {
            Header: "Created at",
            width: 180,
            Cell: ({ row }: { row: { original: Activity } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 130,
            Cell: ({ row }: { row: { original: Activity } }) => (
                <div>
                    <ViewOrEditLinkButton
                        editLink={"/activities/" + row.original.id}
                        readLink={"/activities/" + row.original.id}
                        subjectType={Subjects.Activities}
                        obj={row.original}
                    />
                    <Can
                        I={Action.Delete}
                        this={subject(Subjects.Activities, {
                            ...row.original,
                        })}
                    >
                        <DeleteButtonWithConfirm
                            variant="secondary"
                            className="ml-1"
                            onClick={() =>
                                deleteActivity({ activityId: row.original.id })
                            }
                        />
                    </Can>
                </div>
            ),
        },
    ];

    return (
        <div>
            <div className="section mb-3">
                <Row><Col><h1>{ObjectNames.activities.en}</h1>
                    <div className="subtitle">
                        {Sentences.activityEditSubtitle.en}
                    </div>
                </Col><Col md="auto">   <Can I={Action.Create} a={Subjects.Activities}>
                    <ActivityModal />
                </Can></Col></Row>

            </div>
            <div>
                <ErrorBar errorMessage={errorMessage} />


            </div>
            <TableWithPagination
                fetchData={(
                    pageIndexToFetch: number,
                    pageSizeToFetch: number,
                    filterToFetch: string,
                    sortToFetch: string,
                    showPublicObjectsToFetch: boolean
                ) => {
                    setPageIndex(pageIndexToFetch);
                    setPageSize(pageSizeToFetch);
                    setFilter(filterToFetch);
                    setSort(sortToFetch);
                    setShowPublicObjects(showPublicObjectsToFetch);
                }}

                showPublicObjectsToggle={true}
                loading={isFetching}
                columns={columns}
                data={data?.results || []}
                itemCount={data?.total || 0}
                enableFiltering={true}
                sortableProps={[
                    { name: 'Name', accessor: 'name' },
                    { name: 'Visibility', accessor: 'visibility' },
                    { name: 'Created at', accessor: 'createdAt' }
                ]}
            />
        </div>
    );
}
