import { useContext } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { LangContext } from "src/lang/lang";
import { useNavigate } from "react-router-dom";
import RoutesDef from "src/routes/RoutesDef";

export const Home = () => {
    const { Sentences } = useContext(LangContext);
    const navigate = useNavigate();

    const shortcuts = [
        { label: "Quick start guide", path: RoutesDef.TUTORIALS },
        { label: "Releases", path: RoutesDef.RELEASES },
        { label: "About VRExplorer", path: RoutesDef.ABOUT },
    ];

    return (
        <>
            <Row className="bg text-center">
                <Col className="my-auto">
                    <h1 className="display-4">
                        {Sentences.welcome.en}
                    </h1>
                    <Row className="mx-auto mt-4" style={{ width: "500px" }}>
                        {shortcuts.map((shortcut, index) => (
                            <Col key={index} className="mb-3">
                                <Button
                                    variant="secondary"
                                    style={{ fontSize: '0.9em' }}
                                    onClick={() => navigate(shortcut.path)}
                                >
                                    {shortcut.label}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>

        </>
    );
};
