import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LogIn } from "../features/auth/LogIn";
import { LogOut } from "../features/auth/LogOut";
import { CasLogin } from "../features/auth/CasLogin";
import { GoogleLogin } from "../features/auth/GoogleLogin";
import { WebSocketTest } from "../features/auth/WebSocketTest";
import LoginForwadedPage from "../app-parts/LoginForwadedPage";
import TerrainsPage from "../features/terrains/TerrainsPage";
import ActivitiesPage from "../features/activities/ActivitiesPage";
import CreateTerrainPage from "../features/terrains/CreateTerrainPage";
import EditCreateActivityPage from "../features/activities/EditCreateActivityPage";
import EditUserPage from "../features/users/edit-user-page";
import CreateJobPage from "../features/job/CreateJobPage";
import EditJobPage from "../features/job/EditJobPage";
import { JobsPage } from "../features/job/JobsPage";
import SessionsPage from "../features/sessions/SessionsPage";
import EditSessionPage from "../features/sessions/EditSessionPage";
import PrivateRoutes from "../components/PrivateRoute";
import CourseSessionProgressExplorerPage from "../features/session-records/session-progress/CourseSessionProgressExplorerPage";
import { TerrainModelsPage } from "../features/terrains/terrain-models/terrain-models-page";
import { EditTerrainModelPage } from "../features/terrains/terrain-models/terrain-model-editor-page";
import { LayerEditorPage } from "../features/layer/terrain-layer-editor-page";
import { LangContext, Sentences } from "../lang/lang";
import { About } from "../app-parts/About";
import { EditReleasesPage } from "../features/binary-artifacts/edit-releases-page";
import { MyTeamsPage } from "src/features/teams/my-teams-page";
import { TeamEditorPage } from "src/features/teams/edit-team-page";
import { TeamRoleEditorPage } from "src/features/teams/roles/role-editor-page";
import { TeamRolesPage } from "src/features/teams/roles/roles-pages";
import { TeamRoleCreatorPage } from "src/features/teams/roles/role-creator-page";
import { AllTeamsPage } from "src/features/teams/all-teams-page";
import SessionRecordsPage from "src/features/session-records/SessionRecordsPage";
import { TerrainLayersPage } from "src/features/layer/terrain-layers-page";
import { TerrainEditorPage } from "src/features/terrains/terrain-editor-page";
import AllUsersPage from "src/features/users/all-users-page";
import { MyProfilePage } from "src/features/users/my-profile-page";
import UnityLayout from "src/layouts/unity";
import MainLayout from "src/layouts/main";
import SessionsPageUnity from "src/features/sessions/SessionsPage.unity";
import { HomeUnity } from "src/features/screens/Home.unity";
import { Home } from "src/features/screens/Home";
import { BuilderModeUnity } from "src/features/screens/BuilderMode.unity";
import TerrainsPageUnity from "src/features/terrains/TerrainsPage.unity";
import RoutesDef from "./RoutesDef";
import { CreateUser } from "src/features/users/CreateUser";

import React from "react";
import {
    Routes,
    Route,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { AppInfoContext } from "src/features/AppVersionWatcher/BackendVersionProvider";
import { CreateAccount } from "src/features/auth/CreateAccount";
import { RecoverPassword } from "src/features/auth/RecoverPassword";
import { ResetPassword } from "src/features/auth/ResetPassword";
import ActivitiesPageUnity from "src/features/activities/ActivitiesPage.unity";
import SlidesPageUnity from "src/features/activities/SlidesPage.unity";
import { MarkownPageUnity } from "src/features/slides/markdown-page.unity copy";
import StorageUsageAnalyzerPage from "src/features/storage-usage-analyzer/storage-usage-analyzer.page";
import { R } from "src/api/Permissions";
import { Tutorials } from "src/app-parts/Tutorials";


export const RouterSwitch = () => {

    const appVersion = useContext(AppInfoContext);
    const [sentryIsInit, setSentryIsInit] = useState<boolean>(false);
    useEffect(() => {
        if (appVersion?.sentryEnabled) {
            const sentryDSN = appVersion.sentryFrontendDSN
            if (!sentryIsInit) {
                setSentryIsInit(true);
                Sentry.init({
                    dsn: sentryDSN,
                    integrations: [
                        Sentry.reactRouterV6BrowserTracingIntegration({
                            useEffect: React.useEffect,
                            useLocation,
                            useNavigationType,
                            createRoutesFromChildren,
                            matchRoutes
                        }),
                        Sentry.replayIntegration()
                    ],
                    tracesSampleRate: 1.0,
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                });
            }
        }
    }, [appVersion])


    const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
    return (
        <SentryRoutes>
            <Route path={RoutesDef.UNITY_RENDER_SLIDE} element={<MarkownPageUnity />} />
            <Route element={<UnityLayout />}>
                <Route path={RoutesDef.UNITY_HOME} element={<HomeUnity />} />
                <Route path={RoutesDef.UNITY_LOGIN} element={<LogIn clientMode={true} />} />
                <Route path={RoutesDef.UNITY_LOGOUT} element={<LogOut clientMode={true} />} />
                <Route element={<PrivateRoutes clientMode={true} />}>
                    <Route path={RoutesDef.UNITY_SESSIONS} element={<SessionsPageUnity />} />
                    <Route path={RoutesDef.UNITY_BUILDER_MODE} element={<BuilderModeUnity />} />
                    <Route path={RoutesDef.UNITY_SCENES} element={<TerrainsPageUnity />} />
                    <Route path={RoutesDef.UNITY_ACTIVITIES} element={<ActivitiesPageUnity />} />
                    <Route path={RoutesDef.UNITY_SLIDES} element={<SlidesPageUnity />} />
                </Route>
            </Route>
            <Route element={<MainLayout />}>
                <Route element={<PrivateRoutes />}>
                    <Route path={RoutesDef.USERS} element={<AllUsersPage />} />
                    <Route path={RoutesDef.USERS_EDIT} element={<EditUserPage />} />
                    <Route path={RoutesDef.USERS_CREATE} element={<CreateUser />} />
                    <Route path={RoutesDef.TEAM_USERS_CREATE} element={<CreateUser />} />
                    <Route path={RoutesDef.TERRAINS_CREATE} element={<CreateTerrainPage />} />
                    <Route path={RoutesDef.TERRAINS_EDIT} element={<TerrainEditorPage />} />
                    <Route path={RoutesDef.TERRAINS} element={<TerrainsPage />} />
                    <Route path={RoutesDef.MODELS} element={<TerrainModelsPage />} />
                    <Route path={RoutesDef.MODELS_EDIT} element={<EditTerrainModelPage />} />
                    <Route path={RoutesDef.ACTIVITIES} element={<ActivitiesPage />} />
                    <Route path={RoutesDef.ACTIVITIES_CREATE} element={<EditCreateActivityPage />} />
                    <Route path={RoutesDef.ACTIVITIES_EDIT} element={<EditCreateActivityPage isEdit />} />
                    <Route path={RoutesDef.JOBS} element={<JobsPage />} />
                    <Route path={RoutesDef.JOBS_CREATE} element={<CreateJobPage />} />
                    <Route path={RoutesDef.JOBS_EDIT} element={<EditJobPage />} />
                    <Route path={RoutesDef.LAYERS} element={<TerrainLayersPage />} />
                    <Route path={RoutesDef.LAYERS_MANAGED_EDIT} element={<LayerEditorPage />} />
                    <Route path={RoutesDef.LAYERS_EDIT} element={<LayerEditorPage />} />
                    <Route path={RoutesDef.SESSIONS} element={<SessionsPage />} />
                    <Route path={RoutesDef.SESSIONS_RECORDS} element={<SessionRecordsPage />} />
                    <Route path={RoutesDef.SESSIONS_EDIT} element={<EditSessionPage />} />
                    <Route path={RoutesDef.SESSIONS_RECORDS_EDIT} element={<CourseSessionProgressExplorerPage />} />
                    <Route path={RoutesDef.ADMIN_TEAMS_EDIT} element={<TeamEditorPage />} />
                    <Route path={RoutesDef.ADMIN_TEAMS_ROLES_EDIT} element={<TeamRoleEditorPage />} />
                    <Route path={RoutesDef.ADMIN_TEAMS_ROLES} element={<TeamRolesPage />} />
                    <Route path={RoutesDef.ADMIN_TEAMS_ROLES_CREATE} element={<TeamRoleCreatorPage />} />
                    <Route path={RoutesDef.ADMIN_MY_TEAMS} element={<MyTeamsPage />} />
                    <Route path={RoutesDef.ADMIN_MY_PROFILE} element={<MyProfilePage />} />
                    <Route path={RoutesDef.ADMIN_MY_TEAMS_DETAILS} element={<TeamEditorPage />} />
                    <Route path={RoutesDef.ADMIN_TEAMS} element={<AllTeamsPage />} />
                    <Route path={RoutesDef.RELEASES} element={<EditReleasesPage />} />
                    <Route path={RoutesDef.STORAGE_USAGE_ANALYZER} element={<StorageUsageAnalyzerPage />} />
                </Route>

                <Route path={RoutesDef.LOGIN} element={<LogIn />} />
                <Route path={RoutesDef.LOGOUT} element={<LogOut />} />
                <Route path={RoutesDef.ACCOUNT_CREATE_ACCOUNT} element={<CreateAccount />} />
                <Route path={RoutesDef.ACCOUNT_RECOVER} element={<RecoverPassword />} />
                <Route path={RoutesDef.ACCOUNT_RESET_PASSWORD} element={<ResetPassword />} />
                <Route path={RoutesDef.ABOUT} element={<About />} />
                <Route path={RoutesDef.TUTORIALS} element={<Tutorials />} />
                <Route path={RoutesDef.CAS_LOGIN} element={<CasLogin />} />
                <Route path={RoutesDef.GOOGLE_LOGIN} element={<GoogleLogin />} />
                <Route path={RoutesDef.WEBSOCKET} element={<WebSocketTest />} />
                <Route path={RoutesDef.LOGIN_TASK_FORWARDED} element={<LoginForwadedPage />} />
                <Route path={RoutesDef.HOME} element={<Home />} />
            </Route>

        </SentryRoutes >

    );
};
